.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 300px;
  background: linear-gradient(rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.95)), url('../../assets/grain.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 18px;
  overflow: hidden;
  flex-direction: column;
}

.container h1 {
  z-index: 10;
  margin: 0px;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  border-radius: 18px;
}

.myText {
  color: white;
  font-size: 64px;
}

.mySelect {
  background-color: #252525;
  font-family: 'proxima-nova', sans-serif;

  padding: 5px 40px;
  font-size: 24px;
  border-radius: 12px;
  color: white;
  border: var(--ferocious-magenta) 2px solid;
}

.mySelect:focus-visible {
  font-family: 'proxima-nova', sans-serif;
  background-color: #252525;
  padding: 5px 40px;
  font-size: 24px;
  border-radius: 12px;
  color: white;
  border: var(--ferocious-magenta) 2px solid;
}


.mySelect option:hover {
  background-color: #252525;
}


.gradientOverlay {
  position: absolute;
  width: 600px;
  /* Adjust for desired gradient size */
  height: 600px;
  background: radial-gradient(circle, rgba(128, 0, 128, 0.6), transparent 60%);
  border-radius: 50%;
  pointer-events: none;
  filter: blur(80px);
  left: 0;
  transition: transform 0.1s ease-out;
  top: 0;
  overflow: hidden;
}