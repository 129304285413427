.leftDiv {
    width: 50%;
    background-color: #fff;
}

.emailInfo {
    padding: 20px;
}

.clientInfo,
.ferociousPersonnel {
    margin-bottom: 20px;
}

.clientInfo h2,
.ferociousPersonnel h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: var(--ferocious-gray-dark);
}

.clientInfo label,
.ferociousPersonnel label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--ferocious-gray-dark);
}

.clientInfo input,
.ferociousPersonnel input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    font-family: 'proxima-nova', sans-serif;
    box-sizing: border-box;
}

.clientInfo button,
.ferociousPersonnel button {
    padding: 8px 16px;
    background-color: none;
    background: none;
    color: gray;
    border-radius: 50px;
    border: 2px gray solid;
    cursor: pointer;
    transition: all 0.3s;
}

.clientInfo button:hover,
.ferociousPersonnel button:hover {
    color: #6a479c;
    border: 2px #6a479c solid;
    transform: translateY(-3px);
}

.dropDown {
    width: 300px;
}

.personnelRow {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.personnelRow input {
    flex: 1;
    font-family: 'proxima-nova', sans-serif;

}

.contactRow {
    margin-bottom: 10px;
}

.contactInputRow {
    display: flex;
    align-items: center;
    gap: 10px;
}

.contactInputRow button {
    background: none;
    border: none;
    color: black;
    cursor: pointer;
}

.readOnlyInput {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

.checkboxContainer {
    display: flex;
    align-items: center;
    margin-top: 10px;
    flex-direction: row;
}

.checkboxContainer input {
    width: fit-content;
    margin-right: 10px;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modalContent {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 400px;
    text-align: center;
    border-radius: 10px;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}


.sendEmailButton {
    position: relative;
    padding: 10px 20px;
    font-size: 16px;
    border: 2px solid transparent;
    border-radius: 50px;
    background-color: #6a479c;
    color: #ffffff;
    cursor: pointer;
    overflow: hidden;
    transition: border-color 0.3s ease, color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}


.sendEmailButton::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #B23092, #6A479C);
    z-index: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 50px;
}

.sendEmailButton:hover::before {
    opacity: 1;
}

.sendEmailButton:hover {
    border-color: #6a479c;
    color: transparent;
}

.sendEmailButton .buttonText,
.sendEmailButton .buttonArrow {
    transition: transform 0.3s ease, opacity 0.3s ease;
    position: relative;
    z-index: 0;
}

.sendEmailButton .buttonArrow {
    position: absolute;
    color: white;
    bottom: -40px;
    opacity: 0;
}

.sendEmailButton:hover .buttonText {
    transform: translateY(-100%);
    opacity: 0;
}

.sendEmailButton:hover .buttonArrow {
    transform: translateY(-50px);
    opacity: 1;
}

.leftDiv input[type="checkbox"] {
    appearance: none;
    width: 20px;
    /* Adjust the size as needed */
    height: 20px;
    /* Adjust the size as needed */
    background-color: #D5D5D5;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
}

.leftDiv input[type="checkbox"]:checked {
    background-color: #B23092;
}

.leftDiv input[type="checkbox"]:checked::after {
    content: '\e876';
    /* Unicode for the Material Icons checkmark */
    font-family: 'Material Icons';
    font-weight: 600;
    color: white;
    font-size: 16px;
    /* Adjust the size as needed */
}

.leftDiv input[type="text"],
.leftDiv input[type="email"] {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
}

.leftDiv input[type="text"]:hover,
.leftDiv input[type="email"]:hover {
    border-color: #c06ec3;
}

.leftDiv input[type="text"]:focus,
.leftDiv input[type="email"]:focus {
    border-color: #c06ec3 !important;
    ;
}

/* Focus state with higher specificity */
.leftDiv input[type="text"]:focus,
.leftDiv input[type="email"]:focus {
    border-color: pink;
    outline: none;
    /* Remove default outline */
}


.leftDiv input[type="text"]:read-only {
    background-color: rgb(226, 226, 226);
}

.glassesLogo {
    width: 175px;
}

.modalContent h4 {
    margin-top: 0px;
    margin-bottom: 16px;
}

.modalContent h2 {
    margin-bottom: 0px;
}

.modalContent li {
    text-align: center;
    list-style: none;
}

.modalContent .confirmModal,
.modalContent .closeModal {
    width: 48%;
}

.closeModal,
.confirmModal {
    padding: 10px 20px;
    border-radius: 25px;
    border: 2px solid transparent;
    background-color: #70469C;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.closeModal {
    background-color: white;
    color: #70469C;
    border-color: #70469C;
}

.closeModal:hover {
    background-color: #70469C;
    color: white;
}

.confirmModal:hover {
    background-color: #50326f;
    color: #ffffff;
    border-color: #50326f;
}

.modalContent .buttonGroup {
    /* New class for button group */
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.emailHistory ul {
    margin-top: 5px;
    margin-bottom: 5px;
    list-style-type: disc;
    margin-bottom: 20px;
    padding-left: 20px;
}

.emailHistoryItem {
    margin-left: 10px;
    /* Adds space between list items */
    margin-bottom: 5px;
    font-size: 14px;
    color: #888;
}

.emailHistoryItem li {
    text-align: left;
    /* Ensures text is aligned to the left */
    padding-left: 0;
    /* Removes any additional padding */
    margin: 5px 0;
    /* Adds space between list items */

}


li::marker {
    color: #888;
}

.clientInfoHint {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 0px;
    margin-bottom: 10px;
    border-radius: 12px;
    
    background-color: var(--ferocious-gray-light);
}

.hintIcon{
    width: 20px;
    height: 20px;
}

.hintIconContainer{
    border: #50326f;
}


.hintIconContainer {
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 10px; /* Rounded corners */
    background: white; /* Background color */
    border: 2px solid var(--ferocious-purple);
    box-shadow: 0 0 3px var(--ferocious-purple), 
                inset 0 2px 8px rgba(0, 0, 0, 0.3); /* Outer glow + inner shadow */
    position: relative; /* Needed for the shadow effect */
}



.hintText{
    font-weight: 500;
    font-size: 14px !important;
    color: var(--ferocious-gray-medium) !important;
    margin: 10px 0px;
}