.modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 0px 27px -1px rgba(0, 0, 0, 0.20);
    max-width: 700px;
    width: 700px;
    margin: auto;
    text-align: center;
}

.title {
    font-size: 24px;
    font-weight: 700;
    color: var(--ferocious-purple);
    margin-bottom: 20px;
}
.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align everything to the left */
    width: 100%;
}

.formGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px; /* Add spacing between fields */
}

.label {
    font-weight: 600;
    text-align: left;
    display: flex;
    margin-bottom: 5px; /* Space between label and input */
    font-size: 16px;
    width: 100%; /* Make sure label spans full width */
    align-items: center;
    color: var(--ferocious-gray-dark);
}

.input {
    width: 100%; /* Make all inputs the same width */
    padding: 8px;
    border: none;
    border-bottom: 2px solid #ccc;
    font-size: 16px;
    transition: all 0.3s ease;
    font-family: 'proxima-nova', sans-serif;
    font-weight: 400;
    color: var(--ferocious-gray-dark);
    box-sizing: border-box; /* Ensures padding doesn’t affect width */
}

.input:hover {
    border-bottom: 2px solid var(--ferocious-purple);
}

.input:focus {
    outline: none;
    border-bottom: 2px solid var(--ferocious-purple);
}


.buttonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 20px;
}

.saveButton, .cancelButton, .deleteBtn {
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    border: none;
}

.saveButton {
    background-color: var(--ferocious-purple);
    color: white;
}

.cancelButton {
    background-color: #ccc;
    color: black;
}

.memberImage{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-bottom: 0px;
}

.imageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}


.imageContainer .label{
    text-align: center;
}

.uploadContainer {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 5px;
    overflow: hidden;
    border: 2px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
}

.uploadContainer:hover {
    border-color: var(--ferocious-purple);
}

.fileInput {
    display: none;
}

.memberImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.uploadText {
    position: absolute;
    bottom: 5px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 2px 5px;
    border-radius: 5px;
}


.deleteBtn {
    background-color: var(--delete-red);
    color: white;
}

.disabledButton {
    background-color: #ccc !important;
    cursor: not-allowed !important;
    opacity: 0.6;
}



.formGroup input[type="checkbox"] {
    appearance: none;
    width: 20px; /* Adjust the size as needed */
    height: 20px; /* Adjust the size as needed */
    background-color: #D5D5D5;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-size: 0;
}

.formGroup input[type="checkbox"]:checked {
    background-color: #B23092;
}

.formGroup input[type="checkbox"]:checked::after {
    content: '\e876'; /* Unicode for the Material Icons checkmark */
    font-family: 'Material Icons';
    font-weight: 600;
    color: white;
    font-size: 16px; /* Adjust the size as needed */
}

.checkboxLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkboxLabel input {
    margin-right: 10px;
    width: 20px;
}