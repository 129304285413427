.dropdown {
    position: relative;
    width: 20%;

    cursor: pointer;
}

.dropdownHeader {
    background-color: #252525;
    font-family: 'proxima-nova', sans-serif;
    transition: all 0.3s ease-in-out;
    padding: 5px 40px;
    font-size: 24px;
    border-radius: 12px;
    color: white;
    border: var(--ferocious-magenta) 2px solid;
}

.dropdownHeader:hover {
    border-color: #6A479C;
}

.dropdownList {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #252525;
    color: white;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 5px;
    font-size: 24px;
    /* Adjust font size if needed */

    border-radius: 12px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dropdownItem {
    padding: 10px;
    padding-left: 15px;
    cursor: pointer;
}

.dropdownItem:hover {
    background-color: var(--ferocious-purple);
}

.arrow {
    margin-right: 10px;
    transition: transform 0.3s ease;
    font-size: 0.8em;
}

/* Rotate the chevron when dropdown is open */
.rotate {
    transform: rotate(180deg);
}