body {
  margin: 0;
  font-family: 'proxima-nova', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F3F6F9;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  /* Primary Colors */
  --ferocious-purple: #6A479C;
  /* Optional alternative CMYK reference:
     --ferocious-purple-cmyk: 70, 85, 0, 0; */

  --ferocious-magenta: #B23092;
  /* --ferocious-magenta-cmyk: 31, 95, 0, 0; */

  /* The combined gradient (25.4354° angle) */
  --ferocious-gradient: linear-gradient(
    25deg,
    #6A479C 0%,
    #B23092 100%
  );
  /* Secondary Colors */
  --ferocious-gray-dark: #363538;
  /* --ferocious-gray-1-cmyk: 70, 65, 59, 55; */

  --ferocious-gray-medium: #A2A1A3;
  /* --ferocious-gray-2-cmyk: 39, 32, 31, 0; */

  --ferocious-gray-light: #F3F3F3;
  /* --ferocious-gray-3-cmyk: 3, 2, 2, 0; */

  --delete-red: #bd0909
}
