.numPeopleHeader {
    width: auto;
    display: flex;
    background-color: white;
    padding: 10px 20px;
    border-radius: 12px;
    align-items: center;
    -webkit-box-shadow: 0px 0px 27px -1px rgba(0, 0, 0, 0.20);
    -moz-box-shadow: 0px 0px 27px -1px rgba(0, 0, 0, 0.20);
    box-shadow: 0px 0px 27px -1px rgba(0, 0, 0, 0.20);
}

.numPeopleTextContainer {
    background-color: #D8CEE8;
    padding: 10px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-weight: 700;
    color: var(--ferocious-purple);
}


.numPeopleTextNumber {
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: 20px;
}

.numPeopleLabel {
    margin-left: 10px;
    font-size: 24px;
    font-weight: 700;
    color: var(--ferocious-purple);
}

.teamMemberTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    background-color: white;
    border-radius: 12px;
    padding: 10px 20px;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 27px -1px rgba(0, 0, 0, 0.20);
    -moz-box-shadow: 0px 0px 27px -1px rgba(0, 0, 0, 0.20);
    box-shadow: 0px 0px 27px -1px rgba(0, 0, 0, 0.20);
    table-layout: fixed;  /* This ensures fixed column widths */

}

.teamMemberTable th:nth-child(1),
.teamMemberTable td:nth-child(1) {
    width: 15%; 
}

.teamMemberTable th:nth-child(2),
.teamMemberTable td:nth-child(2) {
    width: 20%; 
}

.teamMemberTable th:nth-child(3),
.teamMemberTable td:nth-child(3) {
    width: 20%; 
}

.teamMemberTable th:nth-child(4),
.teamMemberTable td:nth-child(4) {
    width: 30%; 
}

.teamMemberTable th:nth-child(5),
.teamMemberTable td:nth-child(5) {
    width: 10%; 
}


.teamMemberTable th,
.teamMemberTable td {
    padding: 16px 18px;
    text-align: left;
    color: #6f6f6f;
}

.teamMemberTable th {
    background-color: #F3F3F3;
    font-weight: 800;
    color: var(--ferocious-gray-dark);
}

.teamMemberTable tr:hover {
    background-color: #f5f5f5;
    /* Hover effect */
}

.boldCol {
    font-weight: 700;
    color: var(--ferocious-gray-dark) !important;
}

.memberImage {
    width: 40px;
    /* Adjust size */
    height: 40px;
    border-radius: 50%;
    /* Makes it circular if needed */
    margin-right: 8px;
    /* Space between image and text */
    vertical-align: middle;
}

.editBtn{
    background: none;
    border: none;
    padding: 5px;
    align-items: center;
    display: flex;
    font-size: 16px;
    justify-content: center;
    border-radius: 50px;
    z-index: 1000;
    cursor: pointer;
}

.editBtn:hover{
    background-color: #b3b3b3;
}

.searchInput {
    margin-left: auto;
    padding: 12px 12px;
    border-radius: 8px;
    border: 2px solid var(--ferocious-gray-light);
    font-size: 14px;
    outline: none;
    width: 350px;
    transition: all 0.3s ease-in-out;
    font-family: 'proxima-nova', sans-serif;

}

.searchInput:focus {
    border-color: var(--ferocious-purple);
}


.searchInput:hover {
    border: 2px solid var(--ferocious-purple);
    box-shadow: 0 0 3px var(--ferocious-purple), 
                inset 0 2px 5px rgba(0, 0, 0, 0.2); /* Outer glow + inner shadow */
}

.searchInput:focus {
    outline: none; /* Remove default outline */
    border: 2px solid var(--ferocious-purple);
    box-shadow: 0 0 3px var(--ferocious-purple), 
                inset 0 3px 6px rgba(0, 0, 0, 0.25); /* Stronger effects on focus */
}

.searchAndAddContainer {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between button and search input */
    margin-left: auto; /* Pushes them to the right */
}

.addMemberBtn {
    padding: 17px 17px;
    background-color: #D8CEE8;
    color: var(--ferocious-purple);
    border: none;
    size: 20px;
    display: flex;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-weight: 00;
    white-space: nowrap; /* Prevents text from wrapping */
}

.addMemberBtn:hover {
    background-color: var(--ferocious-purple);
    color: white;
}

.addMemberBtn svg{
    margin: 0px;
}

.tag{
    padding: 5px 10px;
    background-color: #D8CEE8;
    border-radius: 8px;
    font-weight: 500;
    color: var(--ferocious-purple);
}

.tagsContainer{
    display: flex;
    gap: 10px;
    margin-top: 10px;
}




.filterDropdown{
    border: 2px solid #cecece;
    background-color: none;
    font-family: 'proxima-nova', sans-serif;
    border-radius: 8px;
    padding: 5px 10px;
    margin-left: 5px;
    background: none;

}

.filterDropdown:focus {
    outline: none;
    border: 2px solid var(--ferocious-purple);
}




.clientInfoHint {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 0px;
    margin-left: 10px;
    margin-bottom: 10px;
    padding-top: 0px;
    border-radius: 12px;
    
}

.hintIcon{
    width: 20px;
    height: 20px;
}

.hintIconContainer{
    border: #50326f;
}


.hintIconContainer {
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 10px; /* Rounded corners */
    background: white; /* Background color */
    border: 2px solid var(--ferocious-purple);
    box-shadow: 0 0 3px var(--ferocious-purple), 
                inset 0 2px 8px rgba(0, 0, 0, 0.3); /* Outer glow + inner shadow */
    position: relative; /* Needed for the shadow effect */
}



.hintText{
    font-weight: 500;
    font-size: 14px !important;
    color: var(--ferocious-gray-medium) !important;
    margin: 10px 0px;
}




/* Responsive Styles */
@media screen and (max-width: 768px) {
    .numPeopleHeader {
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
    }

    .numPeopleLabel {
        font-size: 20px;
        margin-top: 8px;
    }

    .searchAndAddContainer {
        width: 100%;
        flex-direction: column;
        align-items: stretch;
        gap: 5px;
    }

    .searchInput {
        width: 100%;
        font-size: 14px;
        padding: 10px;
    }

    .addMemberBtn {
        width: 100%;
        padding: 12px;
        font-size: 16px;
    }

    .teamMemberTable {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    .teamMemberTable th, .teamMemberTable td {
        padding: 12px 10px;
        font-size: 14px;
    }

    .teamMemberTable th:nth-child(1),
    .teamMemberTable td:nth-child(1) {
        width: auto;
    }

    .teamMemberTable th:nth-child(2),
    .teamMemberTable td:nth-child(2) {
        width: auto;
    }

    .teamMemberTable th:nth-child(3),
    .teamMemberTable td:nth-child(3) {
        width: auto;
    }

    .teamMemberTable th:nth-child(4),
    .teamMemberTable td:nth-child(4) {
        width: auto;
    }

    .teamMemberTable th:nth-child(5),
    .teamMemberTable td:nth-child(5) {
        width: auto;
    }

    .memberImage {
        width: 30px;
        height: 30px;
    }

    .tagsContainer {
        flex-wrap: wrap;
        gap: 5px;
    }

    .tag {
        font-size: 12px;
        padding: 3px 6px;
    }

    .editBtn {
        padding: 5px;
        font-size: 14px;
    }
}

@media screen and (max-width: 480px) {
    .numPeopleHeader {
        padding: 10px;
    }

    .numPeopleTextNumber {
        font-size: 18px;
    }

    .numPeopleLabel {
        font-size: 18px;
    }

    .searchAndAddContainer {
        flex-direction: column;
        align-items: stretch;
        gap: 5px;
    }

    .searchInput {
        width: 100%;
        font-size: 12px;
        padding: 8px;
    }

    .addMemberBtn {
        width: 100%;
        padding: 10px;
        font-size: 14px;
    }

    .teamMemberTable th, .teamMemberTable td {
        font-size: 12px;
        padding: 8px;
    }

    .memberImage {
        width: 25px;
        height: 25px;
    }

    .editBtn {
        font-size: 12px;
    }

    .tag {
        font-size: 10px;
        padding: 2px 4px;
    }
}
