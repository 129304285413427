.searchContainer {
    position: relative;
    margin-bottom: 20px;
}

.searchIcon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #ccc;
}

.searchInput {
    width: 40%;
    padding: 10px 15px 10px 40px;
    /* Adjust padding for icon */
    font-size: 16px;
    font-family: 'proxima-nova', sans-serif;
    
    font-weight: 500;
    border: 2px solid var(--ferocious-gray-light);
    border-radius: 12px;
    transition: all 0.3s ease-in-out;
    
}






.searchInput:focus + .searchIcon,
.searchContainer:hover .searchIcon {
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
}


.searchInput:hover {
    border: 2px solid var(--ferocious-purple);
    box-shadow: 0 0 3px var(--ferocious-purple), 
                inset 0 2px 5px rgba(0, 0, 0, 0.2); /* Outer glow + inner shadow */
}

.searchInput:focus {
    outline: none; /* Remove default outline */
    border: 2px solid var(--ferocious-purple);
    box-shadow: 0 0 3px var(--ferocious-purple), 
                inset 0 3px 6px rgba(0, 0, 0, 0.25); /* Stronger effects on focus */
}

.searchIcon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #ccc; /* Default gray color */
    transition: color 0.3s ease-in-out;
}

/* Change color when input is focused */
.searchContainer:has(.searchInput:focus) .searchIcon {
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
}

/* Ensure the color resets when focus is lost */
.searchContainer:not(:has(.searchInput:focus)) .searchIcon {
    -webkit-text-fill-color: initial;
    color: #ccc; /* Reset to default */
}
