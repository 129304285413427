.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal {
    border-radius: 12px;
    box-shadow: 0px 0px 27px -1px rgba(0, 0, 0, 0.20);
    max-width: fit-content;
    width: 90%;
    position: relative;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}
